import React from "react";

import Logos from "../components/Logos";

// Animations
import Fade from "react-reveal/Fade";

import CUSTOM_HOMES from "../img/custom-homes.jpg";

import SERVICES_DECK from "../img/index/deck.jpg";
import SERVICES_BATHROOM from "../img/index/bathroom.jpg";
import SERVICES_KITCHEN from "../img/index/kitchen.jpg";
import SERVICES_BASEMENT from "../img/index/basement.jpg";

import CountingNumber from "../__components/CountingNumber";

import { MdCheck, MdHome, MdCardTravel, MdBuild } from "react-icons/md";

const IndexPage = () => (
  <div id="home">
    <Logos />

    <div id="index-stats">
      <div className="gradient__top"></div>

      <div className="container">
        <Fade delay={200}>
          <div className="title">
            <h1>Our Vision</h1>
          </div>
        </Fade>

        <Fade delay={600}>
          <p className="title-p">
            The way that a person <strong>feels</strong> about their home is an
            important concept to the company and is a{" "}
            <strong>top priority</strong> when it comes to working with our
            clients. Through an <strong>effortless transition</strong> into a
            new and improved home, clients can enjoy the same comfort of their
            prior residence with a beautiful and enjoyable home makeover.
          </p>
        </Fade>

        <div className="columns is-multiline">
          <Fade delay={1100} bottom distance="40%">
            <div className="column is-one-fourth">
              <div className="exam-div">
                <MdCheck />

                <p className="number">
                  <CountingNumber start={0} end={21} duration={1500} /> days
                </p>

                <p className="label">
                  Renovation project completion guaranteed
                </p>
              </div>
            </div>
          </Fade>

          <Fade delay={1300} top distance="20%">
            <div className="column is-one-fourth">
              <div className="exam-div">
                <MdHome />

                <p className="number">
                  <CountingNumber start={0} end={120} duration={1500} />
                </p>

                <p className="label">Jobsites a year</p>
              </div>
            </div>
          </Fade>

          <Fade delay={1700} bottom distance="40%">
            <div className="column is-one-fourth">
              <div className="exam-div">
                <MdCardTravel />

                <p className="number">
                  <CountingNumber start={0} end={15} duration={1500} />
                </p>

                <p className="label">Years of experience</p>
              </div>
            </div>
          </Fade>

          <Fade delay={2100} top distance="20%">
            <div className="column is-one-fourth">
              <div className="exam-div">
                <MdBuild />

                <p className="number">
                  <CountingNumber start={0} end={7} duration={1500} />
                </p>

                <p className="label">
                  Years of warranty<sup>*</sup>
                </p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>

    <div id="services">
      <Fade delay={200}>
        <h1>Services</h1>
      </Fade>

      <Fade delay={700}>
        <div className="container">
          <p>
            We know your house inside and out, we tackled any project from
            painting a single bathroom all the way to building remarkable custom
            homes so rest assure that you will be in good hands!
          </p>
        </div>
      </Fade>
    </div>

    <div className="container wide">
      <div className="columns is-multiline home-services">
        <Fade right distance="30%" delay={1200}>
          <div className="column is-half">
            <img src={SERVICES_BASEMENT} alt="" />

            <div className="content">
              <a
                href="https://homestars.com/companies/2916279-s-g-contracting/media_galleries/13634882"
                target="_blank"
                rel="noreferrer"
              >
                <p>Basement</p>
              </a>
            </div>
          </div>
        </Fade>

        <Fade right distance="30%" delay={1900}>
          <div className="column is-half">
            <img src={SERVICES_BATHROOM} alt="" />

            <div className="content">
              <a
                href="https://homestars.com/companies/2916279-s-g-contracting/media_galleries/13634881"
                target="_blank"
                rel="noreferrer"
              >
                <p>Bathroom</p>
              </a>
            </div>
          </div>
        </Fade>

        <Fade left distance="30%" delay={1900}>
          <div className="column is-half">
            <img src={SERVICES_KITCHEN} alt="" />

            <div className="content">
              <a
                href="https://homestars.com/companies/2916279-s-g-contracting/media_galleries/13719207"
                target="_blank"
                rel="noreferrer"
              >
                <p>Kitchen</p>
              </a>
            </div>
          </div>
        </Fade>

        <Fade left distance="30%" delay={1200}>
          <div className="column is-half">
            <img src={SERVICES_DECK} alt="" />

            <div className="content">
              <a
                href="https://homestars.com/companies/2916279-s-g-contracting/media_galleries/13555451"
                target="_blank"
                rel="noreferrer"
              >
                <p>Deck</p>
              </a>
            </div>
          </div>
        </Fade>
      </div>
    </div>

    <div id="index-hero">
      <div className="bg">RENOVATIONS</div>

      <div className="container">
        <div className="columns">
          <Fade right distance="30%" delay={400}>
            <div className="column is-narrow">
              <img src={CUSTOM_HOMES} className="rounded" alt="" />
            </div>
          </Fade>

          <Fade left distance="10%" delay={700}>
            <div className="column">
              <h1>
                Custom renovations
                <br />
                in the Greater
                <br />
                Toronto Area
              </h1>
              <p>
                Our goal is to transform Toronto and the GTA into a beautiful
                network of custom built modern homes. Add value to your
                residence with progressive home improvements that will make you
                feel good about change and transcend you further into the 21st
                century.
                <Fade top distance="30%" delay={900}>
                  <a
                    className="button"
                    href="tel:+16479752168"
                    style={{ display: "table", marginTop: 30 }}
                  >
                    Call for a quote
                  </a>
                </Fade>
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  </div>
);

export default IndexPage;
