import React from 'react'

class PageHeader extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div
        className="page-header"
        style={{
          backgroundImage: `url('${this.props.bgPhoto}')`,
          backgroundPosition: this.props.backgroundPosition,
        }}
      />
    )
  }
}

export default PageHeader
