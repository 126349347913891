import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

// Animations
import Fade from "react-reveal/Fade";

// MISC
import { Popup } from "react-typeform-embed";

import "../styles/index.scss";

import LOGO from "../img/logo.png";
import LOGO_WHITE from "../img/logo_white.png";
import LOGO_GOPHERLABS from "../img/gopherlabs.svg";

// import ARROW_DOWN from "../img/arrow-down.svg";
import { PhoneFilled } from "@carbon/icons-react";

const isBrowser = typeof window !== "undefined";
if (isBrowser) {
  const WebFont = require("webfontloader");
  WebFont.load({
    google: {
      families: ["Playfair Display:700", "Lato", "Lato:700"],
    },
  });
}

const HeaderHome = ({ transition, handleHamburger, isOpened }) => {
  return (
    <>
      <div style={transition && transition.style}>
        <div
          id="header"
          className="columns"
          style={{
            height:
              isBrowser && window.innerHeight > 600 && window.innerHeight < 800
                ? window.innerHeight + 12 + "px"
                : undefined,
          }}
        >
          <Fade duration={2000}>
            <div className="video">
              <video autoPlay muted loop controls={false}>
                <source src="/videos/hero.mov" type="video/mp4" />
              </video>
            </div>
          </Fade>

          <div className="column half-left">
            <div className="container-half half-left" id="header-left">
              <Fade bottom distance="50%" delay={500}>
                <div className="brand">
                  <Link to={""}>
                    <img src={LOGO} className="logo" alt="SG Contracting" />
                  </Link>

                  <a href="#" className="hamburger" onClick={handleHamburger}>
                    <div />
                    <div />
                    <div />
                  </a>
                </div>
              </Fade>

              <div className="header-hero">
                <Fade top distance="10%" delay={750}>
                  <h1>Where creativity meets infinite possibilities</h1>
                </Fade>

                <Fade bottom distance="50%" delay={750}>
                  <p>Proudly offering remodelling and renovation in the GTA</p>
                  <p style={{ marginTop: 0 }}>
                    Built to perfection with integrity & quality design
                  </p>
                </Fade>

                <Fade bottom distance="50%" delay={1300}>
                  <div style={{ display: "flex" }} className="cta">
                    <Link
                      className="button"
                      to={"/about-us"}
                      style={{ marginRight: 10 }}
                    >
                      Read More
                    </Link>

                    <a className="button is-black" href="tel:+16479752168">
                      <PhoneFilled style={{ marginRight: "0.5rem" }} /> Call for
                      a Quote
                    </a>
                  </div>
                </Fade>
              </div>

              {/* <div className="header-arrow">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();

                    const node = document.getElementById("index-services");
                    jump(node, { easing: easeInOutQuad });
                  }}
                >
                  <img src={ARROW_DOWN} alt="" />
                </a>
              </div> */}
            </div>
          </div>

          <div className="column half-right background">
            <div className="container-half half-right">
              <a href="#" className="hamburger" onClick={handleHamburger}>
                <div />
                <div />
                <div />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const HeaderNotHome = ({ transition, handleHamburger }) => (
  <div style={transition && transition.style} className="container site-header">
    <Link to={""}>
      <img src={LOGO} className="logo" />
    </Link>

    <a className="hamburger" onClick={handleHamburger}>
      <div />
      <div />
      <div />
    </a>
  </div>
);

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const pathname = isBrowser ? window.location.pathname : null;

    return (
      <Fade distance="30%" delay={300}>
        <div id="footer">
          <div className="container">
            <div className="columns">
              <div className="column is-8">
                <div className="logo">
                  <img src={LOGO_WHITE} />
                </div>

                <div className="content">
                  <span>SG Contracting</span>
                  <p>&#169; All Rights Reserved, 2022</p>
                </div>
              </div>

              {pathname && pathname.indexOf("contact-us") === -1 && (
                <div className="column is-4 actions">
                  <Link to={"/contact-us"} className="button is-dark">
                    Contact Us
                  </Link>
                </div>
              )}
            </div>
          </div>

          <a href="https://emoosavi.com" target="_blank" rel="noreferrer">
            <div id="gopherlabs">
              <span className="text">Crafted by</span>

              <img src={LOGO_GOPHERLABS} alt="" />
            </div>
          </a>
        </div>
      </Fade>
    );
  }
}

function TemplateWrapper({ children }) {
  const location = useLocation();

  const [hamburgerOpened, setHamburgerOpened] = useState(false);

  const _handleHamburgerToggle = (event) => {
    if (event) event.preventDefault();

    setHamburgerOpened(!hamburgerOpened);
  };

  const HeaderEl = (hamburgerHandler) => {
    return location.pathname === "/" ? (
      <HeaderHome
        handleHamburger={hamburgerHandler}
        isOpened={hamburgerOpened}
      />
    ) : (
      <Fade>
        <HeaderNotHome
          handleHamburger={hamburgerHandler}
          isOpened={hamburgerOpened}
        />
      </Fade>
    );
  };

  return (
    <div>
      {/* <Helmet
          title="Soroush Gholami Enterprise Inc."
          meta={[
            { name: "description", content: "Custom homes in the GTA area" },
            {
              name: "keywords",
              content:
                "custom homes, gta, toronto, markham, windsor, richmond hill, thornhill",
            },
          ]}
        /> */}

      <div className={"overlay" + (hamburgerOpened ? " open" : "")}>
        <a href="#" onClick={_handleHamburgerToggle}>
          <div className="close">
            <span className="first" />
            <span className="second" />
          </div>
        </a>

        <div className="content">
          <ul>
            <li onClick={_handleHamburgerToggle}>
              <Link to={"/"}>Home</Link>
            </li>

            <li onClick={_handleHamburgerToggle}>
              <Link to={"/about-us"}>About Us</Link>
            </li>

            <li onClick={_handleHamburgerToggle}>
              <Link to={"/contact-us"}>Contact</Link>
            </li>

            <li>
              <Popup id="mMbsNY" size={80}>
                <a
                  href="#"
                  onClick={(e) => {
                    _handleHamburgerToggle(e);
                  }}
                >
                  Quick Quote{" "}
                  <span className="tag" style={{ verticalAlign: "middle" }}>
                    NEW
                  </span>
                </a>
              </Popup>
            </li>
          </ul>
        </div>
      </div>

      <div
        className={
          "content-wrapper" + (hamburgerOpened ? " hamburger-opened" : "")
        }
      >
        {HeaderEl(_handleHamburgerToggle)}

        {children}

        <Footer />
      </div>
    </div>
  );
}

export default TemplateWrapper;
