// Animations
import Fade from "react-reveal/Fade";

// Assets
import TARION from "../img/tarion.png";
import HCRA from "../img/HCRA.png";
import HOMESTARS_2021 from "../img/HS-BOA-2021.png";
import HSBOA from "../img/HS-BOA.png";
import HOMESTARS_BADGE from "../img/homestars_badge.png";

export default function Logos() {
  return (
    <div className="section__logos">
      <div>
        <Fade delay={200}>
          <h1>Award Winning Team</h1>
          <p>– Year over year –</p>
        </Fade>

        <div className="container wide">
          <div
            className="columns is-multiline is-mobile"
            style={{ alignItems: "center", textAlign: "center" }}
          >
            <Fade top delay={500} distance="80%">
              <div className="column">
                <img src={TARION} style={{ maxHeight: 150 }} alt="Tarion" />
              </div>
            </Fade>

            <Fade top delay={800} distance="80%">
              <div className="column">
                <img src={HCRA} style={{ maxHeight: 200 }} alt="HCRA" />
              </div>
            </Fade>

            <Fade top delay={1200} distance="80%">
              <div className="column">
                <img
                  src={HSBOA}
                  style={{ maxHeight: 200 }}
                  alt="Homestart Best of the Best Winner 2022"
                />
              </div>
            </Fade>

            <Fade top delay={1600} distance="80%">
              <div className="column">
                <img
                  src={HOMESTARS_2021}
                  style={{ maxHeight: 190, marginBottom: "-1rem" }}
                  alt="Homestars verified"
                />
              </div>
            </Fade>

            <Fade top delay={2000} distance="80%">
              <div className="column">
                <img
                  src={HOMESTARS_BADGE}
                  style={{ maxHeight: 150 }}
                  alt="Homestars verified"
                />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}
