import React from "react";

import PageHeader from "../__components/PageHeader";
import CONTACT_US from "../img/pageheaders/contact.jpg";

import { Popup } from "react-typeform-embed";

// Icons
import { PhoneFilled } from "@carbon/icons-react";

const pageMeta = {
  title: "Contact Us",
  desc: (
    <span>
      <div
        style={{
          display: "inline",
          background: "rgba(0, 0, 0, 0.1)",
          padding: "0.25rem 1rem",
          marginRight: "1rem",
          borderRadius: "0.5rem",
        }}
      >
        NEW
      </div>{" "}
      <PhoneFilled
        style={{
          width: "1.25rem",
          height: "1.25rem",
          verticalAlign: "middle",
          marginTop: "-0.35rem",
        }}
      />{" "}
      Get a Quote in 3 hours or less, no home visits required
    </span>
  ),
};
class ContactPage extends React.Component {
  openForm(e) {
    e.preventDefault();

    this.typeformEmbed.typeform.open();
  }

  render() {
    const { transition } = this.props;

    return (
      <div style={transition && transition.style} className="page">
        <PageHeader bgPhoto={CONTACT_US} />

        <div className="container">
          <div className="title-bar">
            <h1>{pageMeta.title}</h1>
            <p>{pageMeta.desc}</p>
          </div>

          {/* <p>Please direct any and all concerns to <b>info@soroushgholami.com</b></p> */}

          <ol style={{ paddingLeft: 16 }}>
            <li>Text your job description to +1 (647) - 975 - 2168</li>
            <li>Attach any images you might find necessary for the job</li>
            <li>Get a response within 3 hours, NO HOME VISITS REQUIRED</li>
          </ol>

          <p style={{ marginTop: 15 }}>
            For major renovations/remodelling, please call the number or fill in
            the form below.
          </p>

          <p>
            <b>Phone Number: (647) - 975 - 2168</b>
          </p>

          <h4>Contact Form:</h4>

          <p style={{ maxWidth: "800px" }}>
            If this is in regards to a potential project you'd like to discuss,
            please provide us with sufficient information and we will get back
            to you within 2 business days.
          </p>

          <Popup id="mMbsNY" size={80}>
            <a className="button" href="#" onClick={this.openForm.bind(this)}>
              Send a message
            </a>
          </Popup>

          {/* 
          <ReactTypeformEmbed
            popup={true}
            autoOpen={false}
            url={"https://soroushgholami.typeform.com/to/mMbsNY"}
            hideHeaders={true}
            hideFooter={true}
            buttonText="Go!"
            style={{ top: 100, display: "none" }}
            ref={(tf) => (this.typeformEmbed = tf)}
          /> */}
        </div>
      </div>
    );
  }
}

export default ContactPage;
