import PropTypes from 'prop-types'
import React, { Component } from 'react'

const isBrowser = typeof window !== 'undefined'
// if (isBrowser) {
// 	css = CSS
// } else {
// 	css = () => true
// }

class CountingNumber extends Component {
  constructor(props) {
    super(props)

    this.state = {
      endNum: props.end,
      currentNum: props.start,
    }
  }

  componentDidMount() {
    console.log(typeof this.state.currentNum)

    this.interval = setInterval(() => {
      if (this.state.currentNum == this.state.endNum) {
        clearInterval(this.interval)
      } else {
        this.setState({
          ...this.state,
          currentNum: this.state.currentNum + 1,
        })
      }
    }, this.props.duration / this.state.endNum)
  }

  render() {
    const { currentNum } = this.state

    return <span>{currentNum}</span>
  }
}

export default CountingNumber
