import React from "react";
import { Link } from "react-router-dom";

import PageHeader from "../__components/PageHeader";
import ABOUT_US from "../img/pageheaders/about.jpg";

const pageMeta = {
  title: "About Us",
  desc: "Lorem ipsum dolor sit amet",
};
const AboutPage = ({ transition }) => (
  <div style={transition && transition.style} className="page">
    <PageHeader bgPhoto={ABOUT_US} />

    <div className="container">
      <div className="title-bar">
        <h1>{pageMeta.title}</h1>
        {/* <p>{pageMeta.desc}</p> */}
      </div>

      <p style={{ marginTop: 30 }}>
        Built from the ground up, our team of professional contractors have been
        working on a set journey, designing and constructing dream homes for
        clients across the GTA. As a team of proud and committed experts, we
        strive to build the ultimate package of a dream home, full of future
        value, comfortability and an array of life experiences.&nbsp; Anything
        that your personal home needs for improvement is our project mission, as
        we aim to maintain and build the satisfaction of our clients.
      </p>

      <p style={{ marginTop: 30 }}>
        The way that a person <strong>feels</strong> about their home is an
        important concept to the company and is a <strong>top priority</strong>{" "}
        when it comes to working with our clients.&nbsp; By ensuring that there
        is an <strong>effortless transition</strong> into a new and improved
        home, clients can enjoy the same comfort of their prior residences with
        a beautiful and enjoyable interior and exterior home makeover.&nbsp; SG
        Contracting offers{" "}
        <strong>complete renovation and home remodeling services</strong> such
        as full basement renovations, kitchen and bathroom remodelling and much
        more in order to provide clients with a vibrant housing experience.
      </p>
      <p>
        The company provides <strong>complimentary quotation services</strong>{" "}
        and welcomes any questions on site that a client may have regarding the
        development of a desirable house project.
      </p>
      <p>
        Followed by an intricate series of steps for complete home project
        management and through exemplary project supervision, SG Contracting can
        help guide clientele towards making their future visions of a dream home
        into a reality.&nbsp;
      </p>
    </div>
  </div>
);

export default AboutPage;
