import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

// Internals
import Layout from "./components/Layout";
import Index from "./pages/index";
import AboutUs from "./pages/about-us";
import ContactUs from "./pages/contact-us";

// MISC
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";

// Styles
import "./styles/index.scss";

function AppInternal() {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.init("688791762648430");
    TagManager.initialize({
      gtmId: "G-7VY7S5VVXW",
    });
  }, []);

  useEffect(() => {
    ReactPixel.track("ViewContent", location.pathname);
  }, [location.pathname]);

  return (
    <Layout>
      <Routes>
        <Route element={<Index />} index />
        <Route element={<AboutUs />} path="about-us" />
        <Route element={<ContactUs />} path="contact-us" />
      </Routes>
    </Layout>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <AppInternal />
    </BrowserRouter>
  );
}
